.main-filter {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0);
  transition: all 0.3s 0s;
  z-index: 10;

  @include media-breakpoint-down(lg) {
    position: fixed;
    overflow: hidden;
    height: 0px;
    z-index: 1001;
    background-color: rgba(248, 248, 248, 0.83);
    overflow-y: auto;

    &--opened {
      height: 100%;
    }
  }

  &--fixed {
    position: fixed;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.16);
  }

  &:before {
    content: '';
    background-color: rgba(248, 248, 248, 0.83);
    height: calc(100% + 25px);
    position: absolute;
    display: block;
    top: -25px;
    left: 0;
    right: 0;

    @include media-breakpoint-down(lg) {
      background-color: #F8F8F8; 
      max-width: auto;
    }
  }

  &__container{
    margin: 0 auto;
    width: 100%;
    max-width: 928px;
    padding: 16px 0;
    position: relative;
    z-index: 100;

    @include media-breakpoint-down(lg) {
      padding-top: 128px;
    }
  }

  &__content {
    width: 100%;
    max-width: 928px;
    margin-left: 120px;

    @include media-breakpoint-down(lg) {
      margin-left: 0;
      padding-left: 17px;
      padding-right: 17px;
    }
  }

  &--fixed-width{
   .main-filter__content{
     max-width: 690px;
   }
  }

  &__row {
    display: flex;

    & + & {
      margin-top: 16px;
    }

    @include media-breakpoint-down(lg) {
      flex-direction: column;
    }

    &--disabled {
      position: relative;
      opacity: 0.5;

      &:before {
        display: block;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(#fff, 0.2);
        z-index: 9;
      }
    }
  }

  &__control {
    position: relative;
    flex: 1 1 25%;

    @include media-breakpoint-down(lg) {
      margin-left: 8px;
      margin-right: 8px;
    }

    &:not(:last-child) {
      margin-right: 8px;

      @include media-breakpoint-down(lg) {
        margin-bottom: 20px;
      }
    }

    &--button {
      flex: none;
      width: 40px;

      .btn {
        border-radius: 12px;
        background-color: #ffffff;
        border: 1px solid #fff;
      }
    }

    select {
      appearance: none;
      line-height: 1;
      text-align: center;

      &::-ms-expand {
        display: none;
      }
    }

    .icon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      color: #9b9b9b;
      z-index: 2;

      &.icon-chevron-down {
        font-size: 10px;
      }
    }

    &--icon-left {
      .icon {
        left: 12px;
      }
    }
    &--icon-right {
      .icon {
        right: 12px;
      }
    }

    &-cleaner {
      display: flex;
      position: absolute;
      width: 10px;
      height: 10px;
      top: 50%;
      right: 10px;
      opacity: 0.4;
      transform: translateY(-50%);
      transition: all 0.3s 0s;
      padding: 0;
      border: none;
      background-color: transparent;

      &:hover {
        opacity: 1;
        cursor: pointer;
      }

      &:focus {
        outline: none;
      }

      svg {
        display: block;
        width: 100%;
        height: 100%;
        margin: auto;
      }

      &.hidden {
        visibility: hidden;
        opacity: 0;
      }
    }
  }

  &__control-field {
    width: 100%;
    height: 40px;
    border-radius: 12px;
    background-color: #ffffff;
    border: 1px solid #fff;
    outline: none;
    font: {
      weight: 500;
      size: 12px;
    };
    color: rgba(11, 31, 53, 0.95);
    line-height: 40px;
    padding: 5px 10px;
    text-align: center;
    transition: all 0.3s 0s;

    &:focus,
    &--active {
      border-color: #a8a6b6;
    }

    &:disabled {
      opacity: 0.5;
    }

    &.error {
      border: 1px solid red;
    }
  }

  &__picker-wrap {
    position: relative;
    .main-filter__control {
      width: 200px;

      @include media-breakpoint-down(lg) {
        width: auto;
      }
    }
    .main-filter__control-field {
      padding-left: 25px;
    }
  }

  &__picker {
    display: none;
    position: absolute;
    top: calc(100% + 2px);
    left: 0;
    z-index: 10;
    box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.48);
    &--show {
      display: block;
    }

    .rdrDayToday .rdrDayNumber span:after {
      background: #22c7b5;
    }
  }

  &__toggler {
    display: none;

    @include media-breakpoint-down(lg) {
      display: flex;
    }

    cursor: pointer;
    position: fixed;
    width: 48px;
    height: 48px;
    top: 30px;
    right: 14px;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    background: #cecdd0;
    z-index: 1003;

    &--opened {
      width: 47px;
      height: 47px;
      display: block;
      background: $color-black;
    }

    .icon-seacrh-m {
      font-size: 24px;
      color: $color-black;
    }

    // В иконке уже есть фон, заменяем цвет иконки на белый, а фон на тёмный,
    // чтобы получить эффект темного крестика
    .icon-close-s {
      position: absolute;
      left: -1px;
      top: -1px;
      width: 100%;
      height: 100%;
      font-size: 50px;
      color: #fff;
    }
  }
}
