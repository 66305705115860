.filter {
  &__title {
    display: block;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 2px;
  }

  &__inn {
    display: block;
    font-weight: 500;
    font-size: 14px;
    color: #B8B8B8;
    line-height: 18px;
  }
}