// Base variables


// Colors
$color-purple: #722ED1;
$color-yellow: #E98C0D;
$color-red: #FF0D19;
$color-green: #41B807;
$color-black: #242232;
$color-white: #fff;
$color-blue: #538DD5;

$color-purple-middle: #D3ADF7;
$color-yellow-middle: #E9CA66;
$color-red-middle: #F27C75;
$color-green-middle: #ACE97D;
$color-blue-middle: #ADC6FF;

$color-purple-light: #F9F0FF;
$color-yellow-light: #FFFBE6;
$color-red-light: #FFEFEE;
$color-green-light: #F4FFEA;
$color-blue-light: #F0F5FF;