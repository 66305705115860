.preloader-field {
  display: block;
  width: 100%;

  $pf: &;

  &__bar {
    display: block;
    height: 24px;
    background-color: #eee;
    border-radius: 8px;
    background: -webkit-gradient(linear, left top, right top, from(rgba(207, 216, 220, 0.2)), color-stop(rgba(207, 216, 220, 0.4)), to(rgba(207, 216, 220, 0.2)));
    background: -webkit-linear-gradient(left, rgba(207, 216, 220, 0.2), rgba(207, 216, 220, 0.4), rgba(207, 216, 220, 0.2));
    background: linear-gradient(90deg, rgba(207, 216, 220, 0.2), rgba(207, 216, 220, 0.4), rgba(207, 216, 220, 0.2));
    -webkit-animation: card-loading 2s ease infinite;
    animation: card-loading 2s ease infinite;
    background-size: 600% 600%;
  }

  &--big {
    #{$pf}__bar {
      height: 48px;
    }
  }

  &--circle {
    width: 12px;
    height: 12px;

    &:not(:last-child) {
      margin-right: 10px;
    }

    #{$pf}__bar {
      width: 12px;
      height: 12px;
      border-radius: 50%;
    }
  }
}

@-webkit-keyframes card-loading {
  0%,
  100% {
    background-position: 0 50%;
  }
  50% {
    background-position: 100% 50%;
  }
}
@keyframes card-loading {
  0%,
  100% {
    background-position: 0 50%;
  }
  50% {
    background-position: 100% 50%;
  }
}