.fr-stats-panel {
  background-color: $color-white;
  padding: 20px 32px;
  margin: 15px 0 8px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  &__item {
    &:not(:last-child) {
      margin-right: 50px;
    }
  }
  &__title {
    display: inline-block;
    margin-bottom: 15px;
    font: {
      size: 12px;
    };
    color: #9b9b9b;
  }
  &__stats {
    display: flex;
    align-items: flex-start;
  }
  &__stats-value {
    font: {
      size: 18px;
      weight: 500;
    };
    color: $color-black;

    &:not(:last-child) {
      margin-right: 18px;
    }

    &--bold {
      font-weight: 600;
    }

    &--purple {
      color: $color-purple;
    }
    &--yellow {
      color: $color-yellow;
    }
    &--blue {
      color: $color-blue;
    }
    &--red {
      color: $color-red;
    }
    &--green {
      color: $color-green;
    }
  }
}