.block-list {
  padding-bottom: 20px;
  position: relative;

  $r: &;

  &--empty {
    display: flex;
    min-height: calc(100% - 116px);
  }

  &--clients {
    #{$r}__item {
      padding-bottom: 24px;
    }
  }

  &--credits {

    #{$r}__item {
      padding-top: 16px;
    }
  }

  &--requisites {
    padding-top: 32px;

    h2 {
      font: {
        size: 24px;
        weight: 500;
      };
      color: #000;
      line-height: 1;
    }

    h4 {
      font: {
        size: 18px;
        weight: 500;
      };
      color: #9b9b9b;
      line-height: 1.33;
    }
  }

  &__item {
    background: #fff;
    width: 100%;
    padding: 16px 20px;
    border-radius: 8px;
    position: relative;

    & + & {
      margin-top: 8px;
    }
    
    @include media-breakpoint-down(lg) {
      padding: 16px;
      overflow: hidden;
      margin-bottom: 20px;
    }

    &[data-toggle='modal'] {
      cursor: pointer;
    }

    @include media-breakpoint-down(lg) {
      .btn-primary {
        font-weight: 600;
        border-radius: 16px;
      }
    }
  }

  &__row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;

    &:first-child {
      padding-top: 0;
      justify-content: start;
    }
    &:last-child {
      margin-top: 20px;
      padding-bottom: 0;
    }

    @include media-breakpoint-down(lg) {
      & {
        flex-wrap: wrap;
      }

      & > a {
        display: block;
        width: 100%;
      }
    }
  }

  &__link {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
  }

  &__posted-time {
    margin-left: 20px;
    display: flex;
    align-items: center;
    font: {
      weight: 500;
      size: 18px;
    };
    color: #4a4a4a;

    @include media-breakpoint-down(lg) {
      margin-top: 8px;
      margin-left: auto;
    }

    svg {
      display: inline-flex;
      margin-top: -3px;
      margin-right: 8px;

      path {
        fill: #22c7b5;
      }
    }

    &--active {
      color: #22c7b5;
    }
  }

  &__info {
    display: flex;
    align-items: center;
    font: {
      size: 20px;
      weight: 500;
    };
    color: #000;
    line-height: 1.2;

    @include media-breakpoint-down(lg) {
      font-size: 18px;

      &--with-icon + & {
        padding-left: 28px;
      }
    }

    .icon {
      width: 18px;
      height: 18px;
      color: #000;
      opacity: 0.48;
      margin-right: 10px;

      &:before {
        font-size: 18px;
        transform: translateY(-3px);
        display: inline-block;
      }
    }

    span:not(.icon) {
      display: inline-block;
      max-width: 430px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      @include media-breakpoint-down(lg) {
        white-space: normal;
      }
    }

    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }

  &__task-info {
    width: 50%;
    flex: none;

    .table {
      margin-bottom: 0;
    }
  }

  &__desc {
    span {
      font: {
        size: 18px;
        weight: 500;
      };
    }
  }

  &__desc-title {
    color: #4a4a4a;
  }

  &__date-from {
    color: #9b9b9b;
    margin-left: 16px;
  }

  &__table {
    margin-bottom: 0;

    @include media-breakpoint-down(lg) {
      display: block;
    }

    tbody {
      @include media-breakpoint-down(lg) {
        display: flex;
        flex-wrap: wrap;
        margin-left: -30px;
      }
    }

    th, td {
      padding: 4px 12px;
      border-top: 0;
      text-align: right;
      
      @include media-breakpoint-down(lg) {
        text-align: left;
        padding-left: 0;
        padding-right: 0;
        display: block;
        font-size: 14px;
        padding-top: 6px;
        padding-bottom: 6px;
      }
    }

    th {
      font: {
        size: 11px;
        weight: 500;
      };
      color: #bbb;
    }

    tr {
      @include media-breakpoint-down(lg) {
        display: flex;
        flex-wrap: wrap;
        margin-left: 30px;
      }
    }

    td {
      font: {
        size: 14px;
        weight: 500;
      };
      color: #4a4a4a;

      @include media-breakpoint-down(lg) {

        span:first-child {
          color: #BBBBBB;
          display: block;
          margin-bottom: 10px;
        }

        & + td {
          margin-left: 30px;
        }
      }
    }
  }

  &__location {
    font: {
      size: 18px;
      weight: 500;
    };
    color: #4a4a4a;
    align-self: flex-start;
  }
}
