.card {

  &.card-new-item {

    $b: '.card-new-item';

    #{$b}__header,
    #{$b}__body {
      display: flex;

      & > div {
        width: 50%;
        flex: none;
      }
    }
    #{$b}__header {
      align-items: center;
      margin-bottom: 24px;
    }
    #{$b}__body {
      align-items: flex-start;

      h3 {
        font: {
          size: 32px;
          weight: 500;
        };
        line-height: 1.25;
        color: #000;
        padding-right: 20px;
      }
    }
    #{$b}__info {
      display: flex;
      align-items: center;

      .divider {
        display: block;
        width: 12px;
        height: 12px;
        background-color: #eee;
        border-radius: 50%;
        flex: none;
        margin: auto 16px auto 10px;
      }
    }
    #{$b}__info-text {
      display: flex;
      align-items: center;

      span {
        display: inline-flex;
        white-space: nowrap;
        font: {
          size: 14px;
          weight: 500;
        };
        line-height: 1.71;
        color: #9b9b9b;

        &:not(:last-child) {
          margin-right: 8px;
        }
      }
    }
    #{$b}__company {
      font: {
        size: 18px;
        weight: 500;
      };
      color: #4a4a4a;
      line-height: 1.44;
      text-align: right;
    }

    &:hover {
      text-decoration: none;
    }
  }
}