.document-timer {
  position: fixed;
  top: 44px;
  left: 16px;
  padding: 10px 8px;
  font: {
    size: 12px;
    weight: 500;
  };
  line-height: 1;
  color: #fff;
  border-radius: 12px;
  background-color: rgba(255, 255, 255, 0.08);
  transition: all 0.5s 0s;

  $dt: &;

  &__tooltip {
    width: 80px;
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    transform: translateY(100%);
    font: {
      size: 10px;
      weight: 500;
    };
    line-height: 1.4;
    color: #fff;
    padding-top: 24px;
    visibility: hidden;
    opacity: 0;
    transition: all 0.5s 0s;
  }

  &:hover {
    cursor: pointer;
    background-color: #fff;
    color: #000;

    #{$dt}__tooltip {
      opacity: 1;
      visibility: visible;
    }
  }
}