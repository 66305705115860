.error-message {
  margin: auto;

  &__title, &__text {
    color: #fff;
  }

  &__title {
    font: {
      size: 24px;
      weight: 500;
    };
    margin-bottom: 32px;
  }

  &__text {
    display: block;
    line-height: 2;
    text-align: center;
    margin-top: 16px;
    opacity: 0.8;
  }

  &__btn {
    max-width: 376px;
    margin: auto;
    text-decoration: none;
  }

  &__phone {
    color: inherit;
    text-decoration: none;
    &:hover {
      color: inherit;
      text-decoration: none;
    }
  }
}