.modal-menu {
  position: fixed;
  bottom: 120px;
  right: 40px;
  width: 100%;
  max-width: 384px;
  border-radius: 16px;
  padding: 16px 0;
  background: #fff;

  @include media-breakpoint-down(lg) {
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin-left: auto;
    margin-right: auto;
    padding: 16px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__item {
    display: flex;
    align-items: center;
    width: 100%;
    min-height: 48px;
    border: none;
    background-color: #fff;
    padding: 0 16px;    

    @include media-breakpoint-down(lg) {
      min-height: 40px;
    }

    img {
      margin-right: 16px;
    }

    span {
      font: {
        size: 20px;
        weight: 500;
      };
      line-height: 1.2;
      color: $color-black;
      text-align: left;

      @include media-breakpoint-down(lg) {
        font-size: 16px;
        text-align: left;
      }
    }

    &:hover {
      text-decoration: none;
      cursor: pointer;
    }

    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  & + .close {
    top: auto;
    left: auto;
    bottom: 24px;
    right: 55px;
    color: #ff0000;
  }

  & + &__close {
    @include media-breakpoint-down(lg) {
      position: absolute;
      display: block;
      width: 48px;
      height: 48px;
      cursor: pointer;
      right: 16px;
      bottom: 16px;
      background-color: #252233;
      border-radius: 50%;
      box-shadow: 0 2px 4px rgba(0,0,0,.5);
      opacity: 1;

      span {
        display: none;
      }

      .close__line {
        position: absolute;
        background: #fff;
        height: 2px;
        width: 22px;
        top: 50%;
        left: 50%;
        margin-left: -11px;

        &:nth-of-type(1) {
          margin-top: -6px;
          transform: rotate(45deg) translate(3px,4px);
        }

        &:nth-of-type(2) {
          margin-top: 4px;
          transform: rotate(-45deg) translate(3px,-4px);
        }
      }
    }
  }
}
