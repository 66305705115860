.progress-statistic {
  margin-bottom: 10px;

  $b: &;

  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    padding: 2px 8px 2px 12px;
    border-radius: 10px;
    border-width: 1px;
    border-style: solid; 

    & + & {
      margin-top: 4px;
    }

    @include media-breakpoint-down(lg) {
      padding: 6px 8px;
    }

    &--red {
      background: $color-red-light;
      border-color: $color-red-middle;
      #{$b}__bar {
        background: $color-red;
      }
      #{$b}__status {
        color: $color-black;
      }
    }

    &--green {
      background: $color-green-light;
      border-color: $color-green-middle;
      #{$b}__bar {
        background: $color-green;
      }
      #{$b}__counter,
      #{$b}__status {
        color: $color-black;
      }
    }

    &--yellow {
      background: $color-yellow-light;
      border-color: $color-yellow-middle;
      #{$b}__bar {
        background: $color-yellow;
      }
      #{$b}__counter,
      #{$b}__status {
        color: $color-black;
      }
    }

    &--blue {
      background: $color-blue-middle;
      #{$b}__bar {
        background: $color-blue;
      }
    }

    &--purple {
      background: $color-purple-light;
      border-color: $color-purple-middle;
      #{$b}__bar {
        background: $color-purple;
      }
      #{$b}__status {
        color: $color-black;
      }
    }
  }

  &__counter {
    font: {
      size: 11px;
      weight: 600;
    };
    color: #fff;
    line-height: 16px;
    position: relative;
    z-index: 2;
  }

  &__status {
    font: {
      size: 8px;
      weight: 600;
    };
    color: #fff;
    line-height: 16px;
    text-transform: uppercase;
    position: relative;
    z-index: 2;
  }

  &__bar {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    z-index: 1;
    border-radius: 10px 0 0 10px;
  }
}
