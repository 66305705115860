.notification {
  display: flex;
  justify-content: center;
  background-color: #ff0000;
  padding: 14px 20px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 30000;

  &__text {
    display: inline-block;
    font: {
      size: 12px;
      weight: 500;
    };
    color: #fff;
    margin-right: 10px;
  }

  &__reload-link {
    padding: 0;
    border: none;
    background-color: transparent;
    opacity: 1;
    transition: all 0.3s 0s;

    &:hover {
      text-decoration: none;
      opacity: 0.8;
      cursor: pointer;
    }
    &:focus {
      outline: none;
    }
  }

  .icon {
    font-size: 12px;
    color: #fff;
  }
}