.bf-container {

  $bf: &;

  &.modal-content {
    display: flex;
    align-items: stretch;
    flex-direction: row;
    border-radius: 0;
    padding: 0;
    background-color: #fff;
  }

  &__sidebar {
    width: 240px;
    flex: 1 1 240px;
    background-color: #f8f8f8;
    padding: 16px 0 0;
    display: flex;
    flex-direction: column;

    &-footer {
      margin: auto 0 0;
      padding: 16px;

      a {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        color: #4a4a4a;
        font: {
          size: 12px;
          weight: 500;
        };
        opacity: 1;
        transition: opacity 0.3s 0s;

        &:hover {
          text-decoration: none;
          opacity: 0.7;
        }

        .icon {
          display: flex;
          margin-right: 16px;
          width: 24px;
          height: 24px;
          align-items: center;
          justify-content: center;
          font-size: 16px;
        }

        &:not(:last-child) {
          margin-bottom: 8px;
        }
      }
    }
  }

  &__content {
    width: calc(100% - 240px);
    flex: 1 1 calc(100% - 240px);
    background-color: #fff;
    padding: 32px 32px 16px;
  }
}