.stages-progress {
  display: flex;
  align-items: center;

  $r: &;

  &__item {
    background: $color-black;
    display: flex;
    align-items: center;
    border-radius: 8px;
    padding: 1px 4px;
    border-width: 1px;
    border-style: solid;

    #{$r}__icon {
      width: 16px;
      height: 16px;
    }
    #{$r}__text {
      font: {
        size: 12px;
        weight: 500;
      }
      line-height: 16px;
    }

    &--green {
      #{$r}__icon {
        color: $color-green;
      }
    }
    &--red {
      #{$r}__icon {
        color: $color-red;
      }
    }
    &--yellow {
      #{$r}__icon {
        color: $color-yellow;
      }
    }
    &--purple {
      #{$r}__icon {
        color: $color-purple;
      }
    }
    &--blue {
      #{$r}__icon {
        color: $color-blue;
      }
    }

    &--confirmed {
      #{$r}__icon {
        display: inline-flex;
      }
      #{$r}__text {
        display: none;
      }
    }
    &--active {
      padding: {
        left: 10px;
        right: 10px;
      }

      &.stages-progress__item--green {
        background: $color-green-light;
        border-color: $color-green-middle;
        color: $color-green;
      }
      &.stages-progress__item--red {
        background: $color-red-light;
        border-color: $color-red-middle;
        color: $color-red;
      }
      &.stages-progress__item--yellow {
        background: $color-yellow-light;
        border-color: $color-yellow-middle;
        color: $color-yellow;
      }
      &.stages-progress__item--purple {
        background: $color-purple-light;
        border-color: $color-purple-middle;
        color: $color-purple;
      }
      &.stages-progress__item--blue {
        background: $color-blue-light;
        border-color: $color-blue-middle;
        color: $color-blue;
      }

      #{$r}__icon {
        display: none;
      }
      #{$r}__text {
        display: inline-block;
      }
    }
    &--disabled {
      background-color: #eee;
      border-radius: 50%;
      width: 16px;
      height: 16px;

      #{$r}__icon {
        display: none;
      }
      #{$r}__text {
        display: none;
      }
    }

    &--active,
    &--disabled {
      // margin: 0 4px;
    }
  }
}
