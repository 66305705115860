.fr-sidebar {
  background: #fff;
  width: 240px;
  box-shadow: 1px 0px 2px rgba(36, 34, 50, 0.15);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 999;

  @include media-breakpoint-down(lg) {
    transition: 200ms ease;
    transform: translateX(-100%);
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    &:hover,
    &:active,
    &:focus {
      transform: translateX(0%);
    }
  }

  $b: &;

  &__logo {
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    top: 24px;
    left: 20px;

    @include media-breakpoint-down(lg) {
      top: 12.3%;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &__menu {
    margin: 100px 0 0;
    padding: 0 16px;
    display: flex;
    flex-direction: column;

    @include media-breakpoint-down(lg) {
      text-align: center;
      margin-top: 128px;
      margin-top: 64.5%;
      margin-top: 16.2rem;
      margin-top: 29.8vh;
    }

    a {
      width: 100%;
      padding: 8px 16px;
      font-size: 16px;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: $color-black;
      text-decoration: none;
      border-radius: 8px;
      cursor: pointer;
      transition: background 300ms ease;

      @include media-breakpoint-down(lg) {
        font-size: 18px;
        line-height: 24px;
        line-height: 0.8rem 1.125rem;
      }

      .icon {
        font-size: 0.75rem;
        margin-right: 16px;
        color: $color-black;
        opacity: 0.8;
        transition: opacity 300ms ease;

        @include media-breakpoint-down(lg) {
          display: none;
        }
      }

      &:hover,
      &.active {
        background: #F6F7FA;
        .icon {
          opacity: 1;
        }
      }

      + a {
        margin-top: 8px;
      }
    }
  }

  #{$b}-bm {
    position: fixed;
    bottom: 1rem;
    left: 1rem;

    @include media-breakpoint-down(lg) {
      position: relative;
      left: auto;
      right: auto;
      bottom: auto;
      top: auto;
      margin-top: auto;
      margin-bottom: 14.5vh;
      padding-left: 24px;
      padding-right: 24px;
      box-sizing: border-box;
    }

    &__statistics {
      background: #F6F7FA;
      width: 208px;
      padding: 10px;
      border-radius: 16px;

      @include media-breakpoint-down(lg) {
        width: 100%;
      }
    }
    // &__statistics-cont {
    //   padding-top: 1rem;
    // }
    #{$b}-bm__statistics-btn {
      @include media-breakpoint-down(lg) {
        font-weight: 600;
      }

      & > a {
        background: #00B2A9;
        font-size: 14px;
        line-height: 24px;
        padding: 5px 15px;
        color: #fff;
        text-decoration: none;
        text-align: center;
        border-radius: 8px;
        display: block;
      }
    }
  }

  &--min {
    width: 76px;

    #{$b}-bm__values,
    #{$b}-bm__statistics {
      display: none;
    }
    #{$b}__logo {
      width: 54px;
      left: 10px;

      img {
        width: 100%;
      }
    }
    #{$b}__menu {

      li {
        padding: 12px 5px;
        display: flex;
        align-items: center;
        flex-direction: column;

        .icon {
          font-size: 24px;
          margin-right: 0;
          margin-bottom: 4px;
        }

        a {
          font-size: 10px;
        }
      }
    }
    #{$b}-bm {
      left: 5px;
    }
  }
}