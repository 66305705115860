/* Modal */
.modal-open {
  .fr-app {
    //background-color: rgba(36, 34, 50, 0.8);
    filter:blur(4px);
    -o-filter:blur(4px);
    -ms-filter:blur(4px);
    -moz-filter:blur(4px);
    -webkit-filter:blur(4px);
  }
  .close {
    display: flex!important;
  }
}

.modal-backdrop {
  background-color: rgba(36, 34, 50, 0.8) !important;
}

.fade-enter {
  opacity: 0.01;
}
.fade-enter-active {
  opacity: 1;
  transition: opacity 0.4s ease-out;
}

.fade-enter-done {
  opacity: 1;
}

.modal.show {
  display: block;

  .modal-dialog {
    transform: none;
    height: 100%;
    box-sizing: border-box;
    margin: 0 auto;
  }
}

.modal-custom {

  .close {
    // position: fixed;
    // top: 20px;
    // right: 20px;
    position: absolute;
    top: 0;
    right: -70px;
    display: none;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    font-size: 2.7rem;
    font-weight: normal;
    text-shadow: none;
    border-radius: 32px;
    background-color: #fff;
    z-index: 99999;
    opacity: 1;
    transition: all 0.3s 0s;
    pointer-events: auto;

    @include media-breakpoint-down(lg) {
      border: 1px solid rgba(0, 0, 0, .3);
      top: 4px;
      right: 4px;
      width: 30px;
      height: 30px;
      font-size: 1.8rem;
    }
  }
}

.modal-custom {

  .modal-lg {
    max-width: 800px;
  }
  .modal-custom-header {
    padding: 0 20px;
    font-size: 24px;
    font-weight: 500;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #000
  }
  .modal-content {
    height: 100%;

    @include media-breakpoint-down(lg) {
      top: 0;
    }
  }
  .fz-c-task-form__content {
    width: 100%;
    // width: auto;
    // flex: 1 1;
  }

  .modal-dialog {
    max-width: 380px;

    &--medium {
      max-width: 500px;
    }
    &--md {
      max-width: 600px;
    }
    &--lg {
      max-width: 800px;
    }
    &--xl {
      width: auto;
      max-width: 1040px;
      margin: 32px auto;
    }
  }

  &--with-help-block {
    .modal-lg {
      max-width: 1070px;
      // padding-right: 270px;
    }
  }

  &--wide-width {
    .modal-content {
      height: auto;
    }
    .modal-lg {
      max-width: 1144px;
    }
  }

  .modal-content {
    max-height: calc(100vh - 12px);
    display: flex;
    flex-direction: column;
    top: 50%;
    transform: translateY(-50%);
  }
  
  .modal-content__body {
    flex: 1;
    overflow: auto;
  
    .loading-indicator {
      min-height: 300px;
      font-size: 24px;
      text-align: center;
      color: #000;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }

    .loading-indicator-text {
      margin-top: 50px;
    }
  }
  
  .modal-content__footer{
    padding: 0 20px 20px 20px;
  }
  
  .modal-content__header{
    margin-bottom: 10px;
  }

  .modal-content__body--has-padding {
    padding: 0 20px;
  }
}

/* modal-custom--empty */
.modal-custom--empty {

  .modal-content {
    border: none;
    background: transparent;

    &__p-0 {
      padding: 0;
    }
  }
}

.restore-pass {

  &__title {
    margin-bottom: 30px;
    font-size: 24px;
    font-weight: 500;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
  }
  &__description {
    font-weight: 500;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    margin-bottom: 30px;
  }
}

/* restore-pass-form */
.restore-pass-form {

  .btn-white {
    height: 48px;
    font-size: 16px;
    font-weight: 500;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: #000;
  }
}

.modal-content {
  border-radius: 16px;
  &--blur {
    filter: blur(4px);
  }
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-bottom: 40px;
    padding: 20px 20px 0 20px;

    &-info {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 24px;
    }
  }
  &__title {
    display: flex;
    align-items: center;

    span {
      display: inline-block;
      font: {
        size: 24px;
        weight: 500;
      };
      color: $color-black;

      @include media-breakpoint-down(lg) {
        font-size: 22px;
      }

      &:not(.icon) {
        // max-width: 430px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &--task span:not(.icon) {
      max-width: 100%;
    }

    .icon {
      font-size: 18px;
      color: $color-black;
      opacity: 0.48;
      margin-right: 5px;
    }

    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }
  &__posted-time {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 18px;
    color: #4a4a4a;

    &--green {
      svg {
        path {
          fill: $color-green;
        }
      }
    }

    svg {
      margin-right: 4px;
    }

    span {
      &:not(:last-child) {
        margin-right: 16px;
      }
    }
  }
  &__footer {
    text-align: right;

    @include media-breakpoint-down(lg) {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-around;
      text-align: center;   
    }

    .btn-primary {
      width: 200px;
      display: inline-block;
      margin-top: 20px;
      margin-left: 15px;
      background-color: #aaaaaa;

      @include media-breakpoint-down(lg) {
        margin-left: 0;
      }
    }
    .btn-primary#camunda_complete,
    .btn-primary#camunda_submit {
      background-color:#44a4ff;
    }
  }
  &__text {
    font: {
      size: 24px;
      weight: 500;
    };
    line-height: 1.33;
    color: #fff;
    text-align: center;
    margin-bottom: 32px;
  }
  &__buttons {

    .btn {
      width: 100%;
      font-weight: 500;

      &:not(:last-child) {
        margin-bottom: 16px;
      }
    }
  }
  &__inner {
    padding: 32px;
    background-color: #fff;
    border-radius: 16px;
    margin-bottom: 40px;
  }
  &__card-new-item {

    .btn {
      max-width: 376px;
      margin: 0 auto;
    }
  }
  &__icon {
    display: flex;
    width: 80px;
    margin: auto auto 32px;

    svg {
      max-width: 100%;
    }
  }
  &__chart-stats {
    
  }
  &--centred {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h3 {
      font: {
        size: 24px;
        weight: 600;
      };
      color: $color-white;
      margin-bottom: 40px;
    }
  }
}

/* modal-search */
.modal-search {

  .modal-dialog {
    width: 800px;
    max-width: 800px;
    height: 100%;
    margin: 0 auto;
    padding: 70px;
    display: flex;

    .modal-content {
      padding: 0;
      height: 100%;
      display: flex;
    }

    .form-search {
      position: relative;
      max-height: 85px;
      top: 50%;
      margin: 0 auto;
      transform: translateY(-50%);
      transition: all 0.5s 0s;

      &__row {
        position: relative;
        height: 85px;
        border-bottom: 4px solid #fff;

        .form-control{
          padding: 0 60px 0 95px;
          font-size: 64px;
          font-weight: normal;
          font-style: normal;
          letter-spacing: normal;
          color: #ff3900;
          text-shadow: 0 0 0 #fff;
          -webkit-text-fill-color: transparent;
          background: transparent;
          height: 72px;
          line-height: 72px;
          vertical-align: middle;
          border: none;

          &:focus{
            outline: none;
            box-shadow: none;
          }
          &::placeholder{
            color: #fff;
          }
        }
        .icon-search {
          position: absolute;
          left: 20px;
          top: 16px;
        }
        .icon-prelouder {
          position: absolute;
          top: 30px;
          right: 10px;
        }
      }

      .autocomplete, .button-container {
        visibility: hidden;
        opacity: 0;
        transition: all 0.5s 0s;
      }

      .button-container {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 64px;
      }

      &.filled {
        max-height: 1000px;
        top: 0;
        transform: translateX(0);

        .autocomplete,
        .button-container {
          visibility: visible;
          opacity: 1;
        }
      }
    }
  }
}

.modal-dialog {
  &--transparent {
    background-color: transparent;
  }
}

.fzp-c-custom-decision {
  border-radius: 0 0 16px 16px;
}