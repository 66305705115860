.empty-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;

  &__icon {
    margin-bottom: 49px;
  }

  &__title {
    display: inline-block;
    font: {
      size: 24px;
      weight: 600;
    };
    color: $color-black;
    margin-bottom: 32px;
  }

  p {
    text-align: center;
    font: {
      size: 18px;
      weight: 500;
    };
    color: #4a4a4a;
  }
}