@charset "UTF-8";

@font-face {
  font-family: "farzoom";
  src: url("../../fonts/farzoom.woff") format("woff"),
  url("../../fonts/farzoom.ttf") format("truetype"),
  url("../../fonts/farzoom.eot?#iefix") format("embedded-opentype"),
  url("../../fonts/farzoom.svg#farzoom") format("svg");
  font-weight: normal;
  font-style: normal;

}

[data-icon]:before {
  font-family: "farzoom" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "farzoom" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-calendar:before {
  content: "\61";
}
.icon-chevron-down:before {
  content: "\62";
}
.icon-chevron-right:before {
  content: "\63";
}
.icon-close-s:before {
  content: "\64";
}
.icon-copy:before {
  content: "\65";
}
.icon-exit:before {
  content: "\66";
}
.icon-eye:before {
  content: "\67";
}
.icon-file:before {
  content: "\68";
}
.icon-load:before {
  content: "\69";
}
.icon-locker:before {
  content: "\6a";
}
.icon-more:before {
  content: "\6b";
}
.icon-ok:before {
  content: "\6c";
}
.icon-profile:before {
  content: "\6d";
}
.icon-reload:before {
  content: "\6e";
}
.icon-save:before {
  content: "\6f";
}
.icon-seacrh-m:before {
  content: "\70";
}
.icon-send:before {
  content: "\71";
}
.icon-settings:before {
  content: "\72";
}
.icon-user:before {
  content: "\73";
}
.icon-user-1:before {
  content: "\74";
}

.icon-doc-2:before {
  content: "\61";
}
.icon-stamp:before {
  content: "\63";
}
.icon-calendar:before {
  content: "\62";
}
.icon-chevron-down:before {
  content: "\64";
}
.icon-chevron-right:before {
  content: "\65";
}
.icon-close-s:before {
  content: "\66";
}
.icon-copy:before {
  content: "\67";
}
.icon-exit:before {
  content: "\68";
}
.icon-eye:before {
  content: "\69";
}
.icon-file:before {
  content: "\6a";
}
.icon-icons8-checkmark:before {
  content: "\6b";
}
.icon-icons8-google-docs:before {
  content: "\6c";
}
.icon-icons8-guest:before {
  content: "\6d";
}
.icon-icons8-rubber-stamp-bottom-view:before {
  content: "\6e";
}
.icon-icons8-yin-yang:before {
  content: "\6f";
}
.icon-load:before {
  content: "\70";
}
.icon-locker:before {
  content: "\71";
}
.icon-more:before {
  content: "\72";
}
.icon-ok:before {
  content: "\73";
}
.icon-profile:before {
  content: "\74";
}
.icon-reload:before {
  content: "\75";
}
.icon-save:before {
  content: "\76";
}
.icon-seacrh-m:before {
  content: "\77";
}
.icon-send:before {
  content: "\78";
}
.icon-settings:before {
  content: "\79";
}
.icon-user:before {
  content: "\7a";
}
.icon-user-1:before {
  content: "\41";
}

i.icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.icon-dots {
  display: flex;
  align-items: center;
  justify-content: center;

  .dot {
    width: 4px;
    height: 4px;
    background-color: #9b9b9b;
    border-radius: 50%;
    margin: 0 1px;
  }
}

.icon {
  &--purple {
    color: $color-purple;
  }
  &--red {
    color: $color-red;
  }
  &--yellow {
    color: $color-yellow;
  }
  &--blue {
    color: $color-blue;
  }
  &--green {
    color: $color-green;
  }
}