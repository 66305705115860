.autocomplete {

  &__item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 24px;
    background-color: #fff;
    margin: 8px 0 0;
    cursor: pointer;
    &:hover {
      text-decoration: none;
    }
  }

  &__text {
    font: {
      size: 14px;
      weight: 500;
    };
    color: #000;
    line-height: 1.71;

    span {
      color: #9b9b9b;
    }

    &:not(:last-child) {
      margin: 0 48px 0 0;
    }

    &--title {
      margin: 0 auto 0 0 !important;
      max-width: 275px;
      span {
        color: inherit;
      }
    }
  }

  &__identity {
    color: #ff3900 !important;
  }
}