.dropdown-menu {
  border: none;
  min-width: 100%;
  border-radius: 0;
  box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.48);

  .dropdown-item {
    padding: 12px 20px;
    font: {
      size: 14px;
      weight: 500;
    };
    &:hover {
      cursor: pointer;
    }
  }
}

.dropdown {

  $r: &;

  &.disabled {
    opacity: 0.5;
    &:after {
      display: block;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 12px;
      background-color: rgba(255, 255, 255, 0.2);
      z-index: 9;
    }
  }

  &--company {

    #{$r}-item {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &__button-container {
        display: flex;
        justify-content: center;
        padding: 16px;

        .btn {
          position: relative;
          display: inline-flex;
          background-color: rgba(36, 34, 50, 0.8);
          font-size: 12px;
          color: #fff;
          align-items: center;
          justify-content: center;
          width: 100%;
          max-width: 212px;

          .icon {
            font-size: 20px;
            margin-left: 0;
            color: #fff;
            margin-right: 10px;
            opacity: 1;
          }
        }
      }
    }
  }
}