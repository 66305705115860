.preloader {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: block;
  background-color: rgba(#fff, 0.7);
  z-index: 2000;
  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 7rem;
    border-radius: 3px;
    fill: none;
    stroke: #4674ea;
    stroke-linecap: round;
    stroke-width: 8%
  }
  use {
    stroke: #dd3508;
    animation: a 2s linear infinite
  }
  &--static {
    background: none;
    position: static;
    top: auto;
    left: auto;
    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      opacity: 0.3;
      background: #fff;
      z-index: 2;
    }
    svg {
      position: relative;
      top: auto;
      left: auto;
      transform: none;
    }
  }
  &--inverse {
    background-color: rgba(#000, 0.3);
  }
  &--small {
    border-radius: 16px;
    svg {
      max-width: 3.5rem;
    }
  }
  &--big {
    svg {
      max-width: 10rem;
    }
  }
}
  
@keyframes a { 
  to { 
    stroke-dashoffset: 0 
  }
}