.checkbox-list {
  display: flex;
  align-items: stretch;

  & > div {
    & + div {
      margin-left: 4px;
    }
  }

  @include media-breakpoint-down(lg) {
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding-left: 8px;
    padding-right: 8px;

    & > div {
      margin: 8px;
      & > .checkbox-list__item > label {
        border-radius: 8px !important;
      }
    }
  }

  &__item {
    overflow: hidden;

    input {
      display: none;
    }
    label {
      display: flex;
      padding: 3px 10px;
      height: 100%;
      align-items: center;
      margin-bottom: 0;
      border-width: 1px;
      border-style: solid;
      border-radius: 8px;
      border-color: #EFEFEF;
      font: {
        weight: 500;
        size: 12px;
      }
      color: #000;
      mix-blend-mode: overlay;
      cursor: pointer;
      transition: all 0.3s 0s;
      white-space: nowrap;
      &:hover {
        color: #000;
      }
    }

    &--purple {
      label {
        background: $color-purple-light;
        border-color: $color-purple-middle;
        color: $color-purple;
      }

      input {
        &:checked + label {
          color: $color-purple;
          background: $color-white;
        }
      }
    }
    &--red {
      label {
        background: $color-red-light;
        border-color: $color-red-middle;
        color: $color-red;
      }

      input {
        &:checked + label {
          color: $color-red;
          background: $color-white;
        }
      }
    }
    &--yellow {
      label {
        background: $color-yellow-light;
        border-color: $color-yellow-middle;
        color: $color-yellow;
      }

      input {
        &:checked + label {
          color: $color-yellow;
          background: $color-white;
        }
      }
    }
    &--blue {
      label {
        background: $color-blue-light;
        border-color: $color-blue-middle;
        color: $color-blue;
      }

      input {
        &:checked + label {
          color: $color-blue;
          background: $color-white;
        }
      }
    }
    &--green {
      label {
        background: $color-green-light;
        border-color: $color-green-middle;
        color: $color-green;
      }

      input {
        &:checked + label {
          color: $color-green;
          background: $color-white;
        }
      }
    }
    &--white {
      label {
        background: $color-white;
      }

      input {
        &:checked + label {
          background: darken($color-white, 5);
        }
      }
    }

    // &--descendant {
    //   &:hover {
    //     opacity: 0.8;
    //   }
    // }
  }
}
