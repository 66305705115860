.stats-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
  align-self: flex-end;

  @include media-breakpoint-down(lg) {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  &__item {
    display: inline-flex;
    align-items: center;

    &:not(:last-child) {
      margin-right: 20px;
    }

    .icon {
      margin-right: 8px;
    }

    span {
      font: {
        size: 18px;
        weight: 500;
      };
    }

    &--purple {
      color: $color-purple;
    }
    &--yellow {
      color: $color-yellow;
    }
    &--blue {
      color: $color-blue;
    }
    &--red {
      color: $color-red;
    }
    &--green {
      color: $color-green;
    }
  }
}
