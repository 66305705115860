.fr-app.fr-login {
  background-color: #E30611;
}
.fr-login-sidebar__logo {
  background-image: url('./logo.svg');
  width: 337px;
  height: 67px;
}
/* .fr-sidebar {
  background-color:#E30611;
} */
/* .fr-user-menu__main,
.fr-user-menu__dropdown {
  background: #ad050d;
} */
.fr-user-menu.open .fr-user-menu__main {
  opacity: 1;
}
.btn-options__link {
  background-color: #E30611; 
  box-shadow: 0 2px 4px rgba(0,0,0,.5); 
  -webkit-box-shadow:0 2px 4px rgba(0,0,0,.5);
}
.btn-options__link:hover {
  background-color: #ad050d;
}
.fr-sidebar .fr-sidebar-bm__statistics {
  background-color: #fff;
}

.btn-form {
  font-family: system-ui, -apple-system, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
 'Open Sans', 'Helvetica Neue', 'Roboto Rouble', sans-serif;
  -webkit-transition: 0.2s ease;
  transition: 0.2s ease;
  margin: 0 20px;
  cursor: pointer;
  border-radius: 4px !important;
  font-weight: 500 !important;
}

.btn-form--ok {
  color: white;
  background: rgba(240, 50, 38, 0.85);
  border-color: rgba(240, 50, 38, 0.85);
  padding: 13px 25px;
  font-size: 18px;
  border-radius: 4px !important;
}

.btn-form--ok:hover {
  background: #f03226;
  border-color: #f03226;
}

.btn-form--ok:active {
  background: #9e0c00;
  border-color: #9e0c00;
}

.btn-form--none {
  color: rgba(11, 31, 53, 0.95);
  background: transparent;
  border-color: rgba(11, 31, 53, 0.4);
  border-style: solid;
  border-width: 1px;
  padding: 13px 25px;
  font-size: 18px;
  border-radius: 4px !important;
}

.btn-form--none:hover {
  border-color: rgba(11, 31, 53, 0.9);
  background: transparent;
  color: rgba(11, 31, 53, 0.95);
}

.btn-form--none:active {
  background: rgba(11, 31, 53, 0.05);
  border-color: rgba(11, 31, 53, 0.9);
}
/* .fr-sidebar .fr-sidebar-bm .fr-sidebar-bm__statistics-btn > a {
  color: #222;
} */
.fr-sidebar__logo {
  background-image: url('./logo.svg');
  width: 126px;
  height: 20px;
}
.verify-logo {
  background-image: url('./logo.svg');
  background-size: contain;
  width: 126px;
  height: 20px;
  margin-top: 15px;
}
@media (max-width: 1199.98px) {
  .verify-logo {
    margin-top: 0;
  }
}
.hint a,
.buttons a,
.field a,
.client a {
  color: #e30611 !important;
  text-decoration: underline !important;
}
