.deals-list {
  margin-top: 8px;
  padding: 0;
  list-style-type: none;

  $mobileOffset: 10px;

  @include media-breakpoint-down(lg) {
    margin-top: -mobileOffset;
  }

  &__item {
    display: inline-flex;
    font: {
      size: 18px;
      weight: 600;
    };
    color: $color-black;

    @include media-breakpoint-down(lg) {
      margin-top: $mobileOffset;
    }

    &:not(:last-child) {
      margin-right: 16px;
    }

    &--purple {
      color: $color-purple;
    }
    &--yellow {
      color: $color-yellow;
    }
    &--blue {
      color: $color-blue;
    }
    &--red {
      color: $color-red;
    }
    &--green {
      color: $color-green;
    }
  }
}
