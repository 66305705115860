html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}
body {
  line-height: 1;
}
ol, ul {
  list-style: none;
}
blockquote, q {
  quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
a, a:hover, a:focus, a:active{
  outline: none;
}

html, body{
  width: 100%;
  height: 100%;
  margin: 0;
}

body{
  font-family: 'Montserrat', sans-serif;
  background-color: #f8f8f8;
}

.fr-app{
  height: 100%;
}

.fr-container {
  max-width: 928px;
  width: 100%;
  height: 100%;
  margin: 0 auto;

  &--fullWidth {
    max-width: calc(100vw - 240px - 40px);
  }

  &--opened {
    @include media-breakpoint-down(lg) {
      .fr-sidebar {
        transform: translateX(0);
      }

      .main-filter__toggler {
        display: none;
      }
    }
  }

  .fr-content{
    width: 100%;
    height: 100%;
    margin-left: 120px;

    @include media-breakpoint-down(lg) {
      margin-left: 0;
      padding-left: 24px;
      padding-right: 24px;
    }

    &--tasks {
      padding-top: 130px;

      @include media-breakpoint-down(lg) {
        padding-top: 40px;
      }
    }

    &--tasks-rko {
      padding-top: 70px;
    }

    &--clients {
      padding-top: 170px;
      
      @include media-breakpoint-down(lg) {
        padding-top: 40px;
      }
    }

    &--clientsCustom {
      padding-top: 130px;
    }

    &--clientsCustom2 {
      padding-top: 90px;
    }

    &--margin-min {
      margin-left: 40px;
    }
  }

  &--with-notification {
    .fr-sidebar {
      top: 40px;
      height: calc(100% - 40px);
    }
    .fr-content {
      padding-top: 40px;
    }
  }

  &--error {
    width: 100%;
    display: flex;
    background-color: $color-black;

    .logo {
      width: 65px;
      height: 44px;
      position: absolute;
      top: 24px;
      left: 20px;

      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
  }
}

.fr-container-toggler {

  @include media-breakpoint-down(lg) {
    position: fixed;
    z-index: 1000;
    display: block;
    width: 48px;
    height: 48px;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    right: 16px;
    bottom: 16px;
    background-color: #252233;
    border-radius: 50%;
    box-shadow: 0 2px 4px rgba(0,0,0,.5);
  }

  &__item {

    @include media-breakpoint-down(lg) {
      position: absolute;
      background: #fff;
      height: 2px;
      width: 22px;
      top: 50%;
      left: 50%;
      margin-left: -11px;

      &:nth-child(1) {
        margin-top: -6px;
        transition: all 0.4s cubic-bezier(0, 0, 0, 1) 0s;
        transform: none;
      }

      &:nth-child(2) {
        margin-top: -1px;
        transition: all 0.4s cubic-bezier(0, 0, 0, 1) 0s;
        transform: scale(1);
        opacity: 1;
      }

      &:nth-child(3) {
        margin-top: 4px;
        transition: all 0.4s cubic-bezier(0, 0, 0, 1) 0s;
        transform: none;
      }
    }
  }

  &--active {

    .fr-container-toggler__item {

      @include media-breakpoint-down(lg) {

        &:nth-child(1) {
          transform: rotate(45deg) translate(3px, 4px);
        }

        &:nth-child(2) {
          opacity: 0;
          transform: scale(0);
        }
  
        &:nth-child(3) {
          transform: rotate(-45deg) translate(3px, -4px);
        }
      }
    }
  }
}

.fr-fullpage {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: $color-black;
}

.fr-notification {
  .main-filter {
    top:45px;
  }
}
