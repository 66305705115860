.user-panel {
  position: absolute;
  bottom: 8px;
  left: 8px;

  $up: &;

  &__main {
    display: flex;
    align-items: center;
    padding: 8px 16px;
    border-radius: 8px;
    background-color: rgba(255, 255, 255, 0.08);
    width: 208px;

    span {
      font: {
        size: 14px;
        weight: 500;
      };
      line-height: 1.71;
      color: #fff;
    }

    .icon {
      opacity: 0.64;
      color: #ffffff;
      font: {
        size: 10px;
      };
    }
    .icon-user-1 {
      margin-right: 16px;
    }
    .icon-chevron-down {
      margin-left: auto;
    }
  }
  &__menu {
    width: 208px;
    border-radius: 8px 8px 0 0;
    background-color: rgba(255, 255, 255, 0.08);
    overflow: hidden;
  }
  &__item {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    font: {
      size: 14px;
      weight: 500;
    };
    color: #fff;
    line-height: 1.71;
    padding: 8px 16px;

    &:before {
      display: block;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 0;
      height: 100%;
      background-color: #9b9b9b;
      opacity: 0.1;
      z-index: 1;
      transition: all 0.3s 0s;
    }

    .icon {
      width: 16px;
      height: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 16px;

      &:before {
        font-size: 13px;
      }
    }

    &:hover {
      text-decoration: none;
      color: #fff;

      &:before {
        width: 100%;
      }
    }
  }
  &__dropdown {
    display: none;
  }

  &.open {

    #{$up}__main {
      opacity: 0.4;
      border-radius: 0 0 0.5rem 0.5rem;
    }

    #{$up}__dropdown {
      display: block;
    }
  }
}