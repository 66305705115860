//FIX: старт заявки на лимит
[ng-controller*="ext_modules_bgPaLimit_ctrls_startFormCtrl"] h4 {
  font-size: 18px;
  color: #504E5B;
  margin-bottom: 15px;
  font-weight: 500;
  line-height: 27px;
  padding-top: 2px;
}

[ng-controller*="ext_modules_bgPaLimit_ctrls_startFormCtrl"] input.field {
  padding: 9px 16px;
  border: 1px solid #DDDDDD;
  border-radius: 15px;
  width: 100%;
  font-size: 14px;
}

[ng-controller*="ext_modules_bgPaLimit_ctrls_startFormCtrl"] input.field:focus {
    border: 1px solid #EB9A42;
  box-shadow: 0 0 0 1px #EB9A42;
  outline: none;
}

[ng-controller*="ext_modules_bgPaLimit_ctrls_startFormCtrl"] .field label {
  display: block;
  font-size: 14px;
  line-height: 18px;
  color: #000000;
  font-weight: 500;
  opacity: 1;
  margin-bottom: 4px;
}

[ng-controller*="ext_modules_bgPaLimit_ctrls_startFormCtrl"] .field .button{
  background: #504E5B;
  border-color: #504E5B;
  border-radius: 8px;
  color: #fff;
  text-decoration: none;
  padding: 8px 40px;
  white-space: nowrap;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  border: 1px solid;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
}

[ng-controller*="ext_modules_bgPaLimit_ctrls_startFormCtrl"] .field .button:hover{
  background: #787684;
  border-color: #787684;
}
  
[ng-controller*="ext_modules_bgPaLimit_ctrls_startFormCtrl"] .field .button:active{
  background: #504E5B;
  border-color: #504E5B;
  outline: none;
} 
[ng-controller*="ext_modules_bgPaLimit_ctrls_startFormCtrl"] td{
  position: relative;
  padding: 0;
} 
[ng-controller*="ext_modules_bgPaLimit_ctrls_startFormCtrl"] td + td{
  position: relative;
  padding: 0;
  padding-left: 20px;
} 

[ng-controller*="ext_modules_bgPaLimit_ctrls_startFormCtrl"] div.field{
  display: flex;
  flex-wrap: wrap;
  /* align-items: stretch; */
} 
[ng-controller*="ext_modules_bgPaLimit_ctrls_startFormCtrl"] div.field label{
  flex-basis: 100%;
} 
[ng-controller*="ext_modules_bgPaLimit_ctrls_startFormCtrl"] div.field input{
  flex: 1;
  height: 39px;
} 
[ng-controller*="ext_modules_bgPaLimit_ctrls_startFormCtrl"] div.field input + .button{
  margin-left: 10px;
} 

[ng-controller*="ext_modules_bgPaLimit_ctrls_startFormCtrl"] td:not([colspan="2"]):last-child div.field{
  display: flex;
  flex-direction: column-reverse
} 

[ng-controller*="ext_modules_bgPaLimit_ctrls_startFormCtrl"] td:not([colspan="2"]):first-child div.field{
  
} 

/////@at-root


.form_bgPaLimit-startForm h4 {
  font-size: 18px;
  color: #504E5B;
  margin-bottom: 15px;
  font-weight: 500;
  line-height: 27px;
  padding-top: 2px;
}

.form_bgPaLimit-startForm input.field {
  padding: 9px 16px;
  border: 1px solid #DDDDDD;
  border-radius: 15px;
  width: 100%;
  font-size: 14px;
}

.form_bgPaLimit-startForm input.field:focus {
    border: 1px solid #EB9A42;
  box-shadow: 0 0 0 1px #EB9A42;
  outline: none;
}

.form_bgPaLimit-startForm .field label {
  display: block;
  font-size: 14px;
  line-height: 18px;
  color: #000000;
  font-weight: 500;
  opacity: 1;
  margin-bottom: 4px;
}

.form_bgPaLimit-startForm .field .button{
  background: #504E5B;
  border-color: #504E5B;
  border-radius: 8px;
  color: #fff;
  text-decoration: none;
  padding: 8px 40px;
  white-space: nowrap;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  border: 1px solid;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
}

.form_bgPaLimit-startForm .field .button:hover{
  background: #787684;
  border-color: #787684;
}
  
.form_bgPaLimit-startForm .field .button:active{
  background: #504E5B;
  border-color: #504E5B;
  outline: none;
} 
.form_bgPaLimit-startForm td{
  position: relative;
  padding: 0;
} 
.form_bgPaLimit-startForm td + td{
  position: relative;
  padding: 0;
  padding-left: 20px;
} 

.form_bgPaLimit-startForm div.field{
  display: flex;
  flex-wrap: wrap;
  /* align-items: stretch; */
} 
.form_bgPaLimit-startForm div.field label{
  flex-basis: 100%;
} 
.form_bgPaLimit-startForm div.field input{
  flex: 1;
  height: 39px;
} 
.form_bgPaLimit-startForm div.field input + .button{
  margin-left: 10px;

  @include media-breakpoint-down(lg) {
    margin-left: 0;
  }
} 

.form_bgPaLimit-startForm td:not([colspan="2"]):last-child div.field{
  display: flex;
  flex-direction: column-reverse
} 

.form_bgPaLimit-startForm td:not([colspan="2"]):first-child div.field{
  
} 


//FIX: старт заявки на лимит

//FIX: task forms

.injected-form-wrapper{
  .fz-c-edit-tab{
    width: auto;
  }

  @include media-breakpoint-down(lg) {
    .fz-c-edit-form__row {
      flex-direction: column;
    }
  
    // .fz-c-task-form *, .fz-modal__wrapper * {
    //   display: block;
    //   width: 100%;
    //   margin-top: 8px;
    // }
    // .fz-c-task-form__edit-form-cnt {
    //   width: 380px;
    // }
  }
}


//FIX: task forms
#camunda {
  .fz-c-task-form {
    width: 100%;
  }
}