.centred-message-container {
  width: 100%;
  max-width: 430px;

  &--sm {
    max-width: 376px;
  }
  &--lg {
    max-width: 800px;
  }

  &__inner {
    padding: 32px;
    background-color: #fff;
    border-radius: 16px;
    margin-bottom: 40px;
  }
  &__icon {
    display: flex;
    width: 80px;
    margin: auto auto 32px;

    svg {
      max-width: 100%;
    }
  }
  &__text {
    font: {
      size: 24px;
      weight: 500;
    };
    line-height: 1.33;
    color: #fff;
    text-align: center;
    margin-bottom: 32px;
  }
  &__buttons {

    .btn {
      width: 100%;
      font-weight: 500;

      &:not(:last-child) {
        margin-bottom: 16px;
      }

      &.btn-md {
        max-width: 376px;
        margin: {
          left: auto;
          right: auto;
        };
      }
    }
  }
}